@import url(https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Bootstrap Icons */ 

/* COLOR */
.bgOrganizer {
    background-color: #fafafa;
}
.table-hover>tbody>tr:hover {
    --bs-table-accent-bg: #f3fbff;
}
.btn-danger.mbsc-ios.mbsc-button-standard {
    background: #90150c;
    border-color: #90150c;
    color: #fff;
}
.btn:focus,
.btn-organizer,
.btn-icon:hover,
.modal-footer .btn-primary {
    color: #D8F1FC!important;
    background-color: #538EAB;
    border-color: #538EAB;
    box-shadow: unset;
}
.btn-organizer:hover,
.modal-footer .btn-primary:hover {
    color: #538EAB!important;
    background-color: #D8F1FC;
    border-color: #538EAB;
}
.btn-icon,
.searchEvent a,
.mbsc-ios.mbsc-button-flat,
.mbsc-ios.mbsc-scroller-pointer .mbsc-scroller-wheel-item.mbsc-selected,
.mbsc-ios.mbsc-calendar-button.mbsc-button {
    color: #538EAB;
}
.mbsc-ios.mbsc-radio-box:after,
.mbsc-ios.mbsc-selected .mbsc-calendar-cell-text {
    border-color: #538EAB;
}
.mbsc-ios.mbsc-button-standard,
.mbsc-ios.mbsc-segmented-button.mbsc-button,
.mbsc-ios.mbsc-selected .mbsc-calendar-cell-text,
.mbsc-ios.mbsc-switch-track.mbsc-checked:after,
.mbsc-ios.mbsc-segmented-selectbox-inner {
    background: #538EAB;
}
.mbsc-ios.mbsc-button-standard,
.mbsc-ios.mbsc-segmented-button.mbsc-button {
    color: #D8F1FC;
}
.mbsc-ios.mbsc-segmented {
    background: unset;
}

/* Auto width and height */
.mbsc-ios.mbsc-calendar-width-md .mbsc-calendar-day-labels .mbsc-calendar-day-inner {
    min-height: 25rem;
}
.mbsc-calendar-labels {
    position: relative;
}
.mbsc-calendar-cell.mbsc-calendar-day.mbsc-calendar-day-labels {
    width: 14rem;
}
.mbsc-calendar-table {
    height: auto;
    width: auto;
}
.mbsc-popup-content .mbsc-calendar-scroll-wrapper>div {
    height: auto!important;
}

/* RESPONSIVE TABLE */
@media (max-width: 766px) {
    .mbsc-calendar-labels {
        position: absolute;
    }
    .mbsc-calendar-label-text {
        position: unset;
    }
    .mbsc-calendar-table {
        width: 100%;
    }
}

/* Horizontal Scroll Bar */
.mbsc-calendar-slide {
    overflow-x: auto;
    /* overflow-y: hidden; */
    white-space: nowrap;

    overflow-y: auto;
    scrollbar-color: transparent transparent;
    scrollbar-width: thin;
}

.mbsc-flex-1-0-0 {
    flex: auto;
} 

/* .mbsc-calendar-scroll-wrapper>div>div,
.mbsc-calendar-slide {
    transform: unset!important;
} */

/* Copy events from a day */
.mbsc-calendar.mbsc-eventcalendar {
    -webkit-user-select: auto;
        -ms-user-select: auto;
            user-select: auto;
}

/* DISPLAY: NONE */
.mbsc-calendar-week-days.mbsc-flex,
.mbsc-popup-content .mbsc-event .mbsc-event-time,
.tr_Ccam:first-child .btn-position:first-child,
.tr_Ccam:last-child .btn-position:last-child,
.mbsc-hidden-content,
.modal .btn-close,
.event_date,
.durationVal svg,
.duplicateInt,
.interventionTime {
    display: none;
}

/* FONT SIZE */
.MuiPagination-ul button,
#patient-form .btn,
#patient-form select,
#patient-form input,
#ccam-form th {
    font-size: 13px;
}
#patient-form td {
    font-size: 12px;
    padding: 0.5rem;
}
#ccam-form td,
#ccam-form select,
#ccam-form input {
    font-size: 12px;
    padding: 0.3rem;
}

/* INTERVENTION */
.intervention-data:first-child {
    background-color: #D8F1FC;
    color: #3d738d;
    text-align: left;
    font-size: 10pt;
    float: left;
    clear: both;
    border-radius: 0.3rem;
    padding: 0.1rem 0.5rem;
}

/* AGENDA RADIO */
.radio_box {
    display: flex;
    background: #fff;
    border-bottom: 1px solid #ccc;
}
.radio_box_3,
.radio_box label:not(:nth-child(1)) {
    border-top: 1px solid #ccc;
}

/* USER */
#userModal,
#userModal .form-select,
#userModal .form-control,
#userModal .radio_box label {
    font-size: 14px;
}
#userModal .modal-header,
#userModal .modal-footer {
    padding: 0.5rem 1rem;
}
.user_radio,
.user_radio label:not(:nth-child(1)) {
    border-top: unset;
    border-bottom: unset;
}
.mbsc-ios.mbsc-radio-right {
    padding: .875em 2.75em .875em 1em;
}
.radio_box .mbsc-ios.mbsc-form-control-wrapper.mbsc-ltr:before {
    right: unset;
}
.radio_box .mbsc-ios.mbsc-form-control-wrapper.mbsc-ltr:after {
    left: unset;
}

/* AGENDA */
.mbsc-popup-content {
    overflow-x: hidden;
}
.mainEventPopup .mbsc-popup.mbsc-popup-anchored {
    height: auto!important;
    width: 50%!important;
    max-width: 50%!important;
    max-height: -webkit-max-content!important;
    max-height: max-content!important;
    position: fixed;
    top: 50%!important;
    left: 50%!important;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
@media (max-width: 1200px) {
    .mainEventPopup .mbsc-popup.mbsc-popup-anchored {
        width: 75%!important;
        max-width: 75%!important;
    }
}
.mainEventPopup .mbsc-ios.mbsc-textfield {
    height: 1.8rem;
}
.mainEventPopup  .mbsc-ios.mbsc-label-inline {
    line-height: 1.8rem;
}
.mainEventPopup .mbsc-font,
.mainEventPopup .radio_box label {
    font-size: 12px!important;
}
.mainEventPopup .mbsc-popup-buttons button {
    font-size: 15px!important;
}
#agendaSearchBar a {
    text-decoration: none;
    color: #538eab;
}
#agendaSearchBar span.subInfo {
    color: #547c8f;
    font-size: 16px;
}
.eventButtons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 0.3rem;
}
.durationVal {
    width: 45%;
    margin: unset;
}
.durationVal .mbsc-ios.mbsc-select.mbsc-ltr,
.durationVal .mbsc-ios.mbsc-label.mbsc-disabled,
.durationVal .mbsc-ios.mbsc-textfield-inner.mbsc-disabled {
    opacity: unset;
}
.durationVal label {
    border-left: 1px solid #ccc;
}
.intervention {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.intervention>label {
    width: 55%;
}
.form_title {
    padding-left: 1em;
}
.mbsc-form-group {
    margin: unset;
}
.birthDate {
    display: inline-flex;
}
.birthDate label>span {
    width: 45%;
}
.mbsc-ios.mbsc-calendar-label {
    font-weight: normal;
}
.mbsc-ios.mbsc-calendar-width-md 
.mbsc-calendar-label-text,
.mbsc-ios.mbsc-event-text {
    font-size: 8pt;
}
.mbsc-calendar-label-wrapper {
    pointer-events: unset;
}

/* STRIKE & TOOLTIP TEXT */
span.cancelledEvent,
span.postponedEvent {
    text-decoration: line-through;
}
.mbsc-calendar-label-inner .eventDescription {
    visibility: hidden;
    position: fixed;
    width: auto;
    padding: 1px 2px 2px 2px;
    font-size: 11px;
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
    text-align: center;
}

.mbsc-calendar-label-inner:hover .eventDescription {
    visibility: visible;
}

/* ADMIN */
.adminNameList {
    overflow-y: scroll;
    white-space: nowrap;
    height: 250px;
    border: 1px solid #ccc;
    padding: 1rem;
    line-height: 2rem;
    list-style-type: none;
    cursor: pointer;
}
.adminNameList li:hover {
    background-color: #D8F1FC;
}
.adminMenu {
    padding: 2rem;
    font-weight: 600;
}
.adminMenu p {
    cursor: pointer;
}

/* USER DELETE */
.modal-footer .btn-secondary, 
.modal-footer .btn-primary {
    font-size: 0;
}
.modal-footer .btn-secondary:after {
    font-size: .875rem;
    content: 'Annuler';
}
.modal-footer .btn-primary:after {
    font-size: .875rem;
    content: 'Supprimer';
}

/* MODAL */
@media (min-width: 576px) {
    .modal-dialog {
        max-width: 700px;
        margin: 1.75rem auto;
    }
    .modal-footer {
        justify-content: space-between;
    }
}

/* LOGIN FORM */
#loginForm {
    margin: 3rem auto;
}
#loginForm .fieldGroup {
    width: 400px;
    margin: auto;
}
#loginForm .form-control {
    width: auto;
}
div.validationMessage {
    color: #dc3545;
}
div.alertMessage {
    text-align: center;
    width: 400px;
    margin: auto auto 2rem;
    font-size: 13.5px;
}
@media (max-width: 420px) {
    #loginForm .fieldGroup,
    div.alertMessage {
        width: 320px;
    }
    div.alertMessage {
        text-align: center;
        margin: auto auto 2rem;
    }
}

/* CCAM */
.btnSearch {
    color: #538EAB;
    font-weight: 600;
}
#ccam-form select,
#ccam-form input[type="text"] {
    border: unset;
}
#ccam-form select,
#ccam-form input {
    padding: 0.2rem;
}
#ccam-form .flexDiv {
    display: flex;
}
#ccam-form .editZoneField {
    width: auto!important;
}
#ccam-form .flexDiv input[type="text"] {
    width: 50px;
}
.modalAddCcam input {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

/* PATIENT */
#patient-form {
    margin-top: 4rem;
}
.paginationBlock ul {
    justify-content: center;
}
#patient-form table th,
#patient-form .MuiPaginationItem-ellipsis {
    pointer-events: none;
}
#praticienPatient {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 0.5rem;
}
#praticienPatient select {
    padding: 0.1rem;
    border: none;
}
@media (min-width: 1200px) {
    #praticienPatient {
        width: -webkit-max-content;
        width: max-content;
    }
    #selectedPraticien, #praticienPatient {
        margin-left: 8rem;
    }
}
@media (min-width: 768px) {
    #patient-form .paginationBlock {
        margin-top: -3.5rem;
    }
}
#patient-form table {
    width: auto;
    table-layout: fixed;
}
#patient-form table td {
    white-space: nowrap;
}
#patient-form table th input {
    pointer-events: auto;
}
#patient-form table th {
    white-space: nowrap;
    padding: 0.5rem 0;
}
th:nth-child(3) .table-header {
    width: 7vh!important;
}
.table-header,
.table-header:hover,
.table-header:focus {
    outline: none;
    border: unset;
    text-align: center;
    font-weight: 600;
    cursor: pointer;
}
.table-header:hover {
    text-decoration: underline;
}
.spinner-grow,
.spinner-border {
    width: 3rem;
    height: 3rem;
    color: #538eab!important;
}
